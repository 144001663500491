import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import ArrowRight from "../components/svg/arrowRight"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Seo from "../components/seo"

const TermsPage = () => (
    <Layout>
        <Seo title="Terms of service" 
            description="Launch Lab's terms of service and privacy policy."
            pathname="/terms/"
        />

        <section role="main" className="bg-purple white">
          <div className="container">
              <div className="row flex flex-jc">
                  <div className="hero-content sub-hero">
                      <h1
                      className="hero-lead"
                      data-sal="slide-up" 
                      data-sal-easing="ease"
                      data-sal-delay="300"
                      data-sal-duration="400"
                      >Terms and Conditions</h1>
                      <div className="btn-row">
                      <button onClick={() => scrollTo('#anchor')} className="btn btn-lg btn-pink">
                          <span className="flex flex-ac down">
                          <ArrowRight />
                          </span>
                      </button>
                      </div>
                  </div>
                </div>
            </div>
        </section>

        <section className="section-pad terms-section" id="anchor">
            <div className="container">
                <div className="row">

                    <p>By using this website you are deemed to have read and agreed to the terms, conditions and privacy policy listed below.</p>

                    <h5>The following terminology applies to these Terms and Conditions and Privacy Policy:</h5>
                    <p>“Client”, “You” and “Your” refers to you, the person accessing the Launch Lab website and accepting the Company’s terms and conditions. “The Company”, “Ourselves”, “We” and “Us”, refers to Launch Lab. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or ourselves. Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>

                    <h6>Disclaimer</h6>
                    <p>The information on this web site is provided on an “as is” basis. To the fullest extent permitted by law, this Company: excludes all representations and warranties relating to this website and its contents or which is or may be provided by any affiliates or any other third party, including in relation to any inaccuracies or omissions in this website and/or the Company’s literature; and excludes all liability for damages arising out of or in connection with your use of this website. This includes, without limitation, direct loss, loss of business or profits (whether or not the loss of such profits was foreseeable, arose in the normal course of things or you have advised this Company of the possibility of such potential loss), damage caused to your computer, computer software, systems and programs and the data thereon or any other direct or indirect, consequential and incidental damages.</p>
                
                    <h6>Links to this website</h6>
                    <p>If you create a link to a page of this website you do so at your own risk.</p>
                
                    <h6>Links from this website</h6>
                    <p>We do not monitor or review the content of other party’s websites which are linked to from this website. Opinions expressed or material appearing on such websites are not necessarily shared or endorsed by us and should not be regarded as the publisher of such opinions or material. Please be aware that we are not responsible for the privacy practices, or content, of these sites. We encourage our users to be aware when they leave our site & to read the privacy statements of these sites. You should evaluate the security and trustworthiness of any other site connected to this site or accessed through this site yourself, before disclosing any personal information to them. This Company will not accept any responsibility for any loss or damage in whatever manner, howsoever caused, resulting from your disclosure to third parties of personal information.</p>
                
                    <h6>Testing & Research</h6>
                    <p>We track user actions on this site to improve the user experience. All data collected is used to improve the website and your experience on it.</p>
                
                    <h6>Notification of Changes</h6>
                    <p>Launch Lab reserves the right to change these conditions from time to time as it sees fit and your continued use of the site will signify your acceptance of any adjustment to these terms.</p>
                
                    <h6>Your information</h6>
                    <p>We collect information on our website visitors ONLY for analytics and analysis purposes. This is done to improve our website and to help our users find the information they’re looking for quickly and with satisfaction. We want you to have a great user experience on our website. The information we collect is not personal and we do not share it with anyone outside of Launch Lab.</p>
                
                    <h6>Collecting information on potential clients</h6>
                    <p>Contacting us is completely optional. Should you use and submit a completed contact form on our website we will respond to you as soon as possible. We will not share your email address and it will only be used to respond to your enquiry. We may offer our website visitors the chance to sign up to an eNewsletter. Should you choose to sign up to receive one of our eNewsletters we will only use your information for purposes of sending you relevant email newsletters related to our services and to the subject of web design, web development, digital marketing, digital strategy and startups that we think you'll find helpful and interesting. If you do sign up for one of our newsletters you will always have the right to unsubscribe and opt out from our eNewsletter list.</p>
                
                    <h6>Updating your personal information</h6>
                    <p>If you have completed a contact form, or signed up for an eNewsletter, on our website and would like your information changed or deleted from our records please <Link to="/contact/">contact us</Link> specifiying your desire to have your information changed or deleted.</p>
                
                    <h6>Feedback</h6>
                    <p>We're always interested in receiving feedback about our website. If you have any feedback or concerns please use the contact form on our contact page.</p>
                
                    <h6>Updates to this Privacy Policy</h6>
                    <p>Launch Lab may review and update this privacy policy from time to time. Any changes can be viewed on this page of the website.</p>

                    <p>Last updated: July 2021.</p>
            
                    
                </div>
            </div>
        </section>

        
    </Layout>
)

export default TermsPage